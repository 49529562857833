.c-social-feed {
    display: flex;
    align-items: center;
    justify-content: center;
    .cmp-container {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 1rem;
        max-width: 780px;
    }
    .adaptiveImage 
    {
        img {
            width: 100%;
            height: auto;
        }
    }
}