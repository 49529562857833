.title {
  &.text-gray {
    color: $color-gray;
  }

  &.text-lime {
    color: $color-lime;
  }

  &.text-hawaiian {
    color: $color-hawaiian;
  }

  &.text-lemonade {
    color: $color-lemonade;
  }

  &.text-punch {
    color: $color-punch;
  }
  &.text-strawberry {
    color: $color-strawberry;
  }

  &.text-colada {
    color: $color-colada;
  }

  &.text-black {
    color: $color-black;
  }
}