.title {
  .cmp-title {
    &.pt-0 {
      padding-top: 0;
    }
    &.pt-2 {
      padding-top: 0.5rem;
    }
    &.pt-4 {
      padding-top: 1rem;
    }
    &.pb-2 {
      padding-bottom: 0.5rem;
    }
    &.pb-4 {
      padding-bottom: 1rem;
    }
    &.pb-6 {
      padding-bottom: 1.5rem;
    }
    &.pb-8 {
      padding-bottom: 2rem;
    }
    &.pb-10 {
      padding-bottom: 2.5rem;
    }
    &.pb-12 {
      padding-bottom: 3rem;
    }
    &.pb-14 {
      padding-bottom: 3.5rem;
    }
    &.pb-16 {
      padding-bottom: 4rem;
    }
  }
}

.cmp-title {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
}
.cmp-title__text {
  margin: 0;
}
.cmp-title__link {
  color: $color-accent;
  text-decoration: none;
  border: 0.125rem solid $color-transparent;
  border-radius: 0.25rem;
  &:hover {
    color: $color-accent-darker;
    text-decoration: underline;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }
  &:focus {
    color: $color-accent;
    text-decoration: underline;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}

.title.m-0 .cmp-title {
  margin-top: 0;
  margin-bottom: 0;
}