.cmp-text {
  margin: 0.75rem 0 0 0;
  color: $color-text;
  font-weight: normal;
  font-size: 1em;
  & b {
    font-weight: bold;
  }
  & p a {
    text-decoration: none;
    border-radius: 0.25em;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-style: solid;
    }
  }
  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: titleFont, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
  & ul li::marker {
    content: "-  ";
  }
}

.text-black .cmp-text {
  color: $color-black;
}

.m-0 .cmp-text {
  > p {
    margin: 0;
    line-height: 1.3;
  }
}

@media screen and (max-height: $screen-v-break) {
  #home .text.text-center.text-5xl.accent-font p {
    font-size: 4vh;
  }
}

.margin-reset {
  margin: 0;
  * {
    margin: 0;
  }
}
