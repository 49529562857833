html:not(.aem-AuthorLayer-Edit) {
  &.main-scroller {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll; 
  }

  .page-section-container {
    height: 100vh; 
    width: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box; 

    >.cmp-container {
      position: relative;
      height: 100%;
      box-sizing: border-box;
      padding-top: $height-header;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    &.padding-unset {
      >.cmp-container {
        padding: 0;
      }
    }
  }
}