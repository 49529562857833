.main-header > .cmp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: $height-header;
  z-index: 10;
  font-size: $font-size-header;
  padding: 0;
  box-sizing: border-box;
  background: $color-gray;
  > .container {
    width: 100%;
    margin: 0 auto;
  }
  .logo-lockup {
    align-items: center;
  }

  .header-logo {
    display: block;
    width: $width-header-logo;
    height: $height-header-logo;
    color: transparent;
    * {
      width: 100%;
      height: 100%;
    }
  }

  .header-tagline {
    display: block;
    width: $width-header-tagline;
    height: $height-header-tagline;

    @media screen and (max-width: $screen-xs) {
      display: block;
      width: $width-header-mobile-tagline;
      height: $height-header-mobile-tagline;
    }
  }

  .cmp-container {
    align-items: center;
  }

  .hamburger-container {
    display: none;
    height: 100%;
    justify-content: center;
    @media screen and (max-width: $screen-desktop) {
      display: block;
    }
    .text {
      margin: 0;
      height: 100%;
      .cmp-text {
        margin: 0;
        height: 100%;
        p {
          display: block;
          height: 100%;
          margin: 0;
        }
      }
    }
  }

  button.navbar-toggle {
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 21px 15px;
    position: relative;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    .icon-bar {
      display: block;
      width: 28px;
      height: 4px;
      border-radius: 0;
      margin: 4px 0;
      background: $color-background;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }

  .navbar-toggle.open {
    .icon-bar:nth-child(3) {
      opacity: 0;
    }
    .icon-bar:nth-child(2) {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: 13px;
    }
    .icon-bar:nth-child(4) {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-top: -16px;
    }
  }

  .button.sr-only span.cmp-button__text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  //=================== Grid layout ===================

  & .cmp-container {
    display: flex;
    & .aem-Grid {
      display: inherit;
      & .image {
        display: inline-flex;
      }
    }
  }

  //=================== Navigation ===================

  .cmp-navigation .navigation,
  .cmp-experiencefragment--header .navigation,
  .text.nav .cmp-text {
    flex-direction: column;
    justify-content: center;
    display: flex;
    margin: 0;
    ul li {
      display: block;
      list-style-type: none;
    }
    > ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
    }
    > ul li.li-dropdown ul {
      flex-direction: column;
    }
    > ul li.li-dropdown ul a {
      text-decoration: none;
      color: $color-text;
    }
  }

  .cmp-navigation__item:not(.cmp-navigation__item--level-0)
    .cmp-navigation__item-link:not(:hover),
  .text.nav .cmp-text ul li ul li.li-dropdown a {
    color: $color-text;
    &:hover {
      color: $color-gray;
    }
  }
  .cmp-navigation__item:not(.cmp-navigation__item--level-0) {
    &:hover {
      a[data-section="flavors-lime_margarita"] {
        color: $color-lime !important;
      }
      a[data-section="flavors-strawberry_daiquiri"] {
        color: $color-strawberry !important;
      }
      a[data-section="flavors-pina_colada"] {
        color: $color-colada !important;
      }
      a[data-section="flavors-blue_hawaiian"] {
        color: $color-hawaiian !important;
      }
      a[data-section="vodka_lemonade"] {
        color: $color-lemonade !important;
      }
      a[data-section="vodka_punch"] {
        color: $color-punch !important;
      }
      &[data-section-li="vodka-lemonade-li"] {
        background-color: $color-lemonade;
        a {
          color: black !important;
        }
      }
      &[data-section-li="vodka-punch-li"] {
        background-color: $color-punch;
        a {
          color: black !important;
        }
      }
    }
  }

  .cmp-navigation__group,
  .text.nav .cmp-text ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .cmp-navigation__item--level-0,
  .text.nav .cmp-text > ul > li {
    position: relative;
    margin-left: 0.5em;
    & > a {
      padding: 0.5625rem 1rem 0.6875rem 1rem;
    }
    &:hover {
      & > .cmp-navigation__item-link,
      a {
        color: $color-text;
      }
    }
    &:focus {
      border: 0.125em solid $color-accent-lighter;
      border-radius: 0.25em;
      outline: none;
      .cmp-navigation__item-link,
      a {
        color: $color-text;
      }
    }
    & > .cmp-navigation__group,
    ul {
      & .vodka-hover-menu {
        left: -80px;
      }
      position: absolute;
      top: 2.8em;
      z-index: 1;
      display: grid;
      width: max-content;
      padding: 0;
      overflow: hidden;
      background: $color-background;
      box-shadow: 0 0.25em 0.5em 0.2em $color-shadow;
      visibility: collapse;
      transition-delay: 0.05s;
    }
  }

  .cmp-navigation__item--level-0:nth-child(2),
  .cmp-navigation__item--level-0:nth-child(3) {
    &:after {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: $color-text-header;
      content: "";
      pointer-events: none;
      clip-path: polygon(50% 45%, 0 0, 100% 0);

      position: absolute;
      right: -6px;
      top: 15px;
      bottom: 0;
    }
    &:hover {
      &:after {
        background-color: $color-text;
      }
    }
    & .cmp-navigation__group .cmp-navigation__item {
      padding: 0.5625em 2em 0.6875em 1em;
      &:hover {
        & a {
          color: $color-text;
        }
      }
    }
  }

  .nav-container:not(.open) .cmp-navigation__item--level-0:nth-child(2),
  .nav-container:not(.open) .cmp-navigation__item--level-0:nth-child(3),
  .nav-container:not(.open) .text.nav .cmp-text ul li.li-dropdown {
    &:hover {
      & > .cmp-navigation__group,
      > ul {
        visibility: visible;
      }
      &:after {
        background-color: $color-text;
      }
    }
  }

  .cmp-navigation__item-link,
  .text.nav .cmp-text {
    ul li a {
      display: block;
      color: $color-text-header;
      text-decoration: none;
      text-transform: uppercase;
    }
    > ul {
      > li {
        > a.cmp-navigation__item-link[data-section].active {
          color: $color-foreground;
          &:after {
            background-color: $color-foreground;
          }
        }
      }
    }
    ul li a[data-section="flavors-lime_margarita"].active {
      color: $color-lime !important;
    }
    ul li a[data-section="flavors-strawberry_daiquiri"].active {
      color: $color-strawberry !important;
    }
    ul li a[data-section="flavors-pina_colada"].active {
      color: $color-colada !important;
    }
    ul li a[data-section="vodka_lemonade"].active {
      color: $color-lemonade !important;
    }
    ul li a[data-section="vodka_punch"].active {
      color: $color-punch !important;
    }
  }

  .cmp-navigation__item--level-0:nth-child(2) a.active:after,
  .text.nav .cmp-text ul li.li-dropdown a.active:after {
    background-color: $color-text;
  }

  @media screen and (max-width: $screen-desktop) {
    .nav-container {
      visibility: collapse;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      background: $color-gray;

      .text.nav .cmp-text {
        height: initial;
        ul.cmp-navigation__group {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1rem;
          position: relative;
          width: 100%;
        }
        ul li {
          margin-left: 0;
          width: 100%;
          a {
            padding: 1.3rem 1rem 1.3rem 0;
            text-align: left;
          }
        }
        ul li ul.cmp-navigation__group.ul-dropdown {
          display: none;
          visibility: visible;
          background: transparent;
          box-shadow: none;
          margin-left: 0;
          top: 0;

          li {
            padding: 0.5625em 0 0.6875em 0;
            a {
              color: $color-text-header;
            }
          }
        }
      }

      .cmp-navigation__item--level-0:nth-child(2):after,
      .text.nav .cmp-text ul li.li-dropdown:after {
        top: 27px;
        left: 200px;
        right: initial;
      }

      .text.nav .cmp-text ul li ul.cmp-navigation__group.ul-dropdown.open {
        display: block;
      }
    }
    .nav-container.open {
      visibility: visible;
      -webkit-overflow-scrolling: touch;
      max-height: $screen-xs;
      overflow: auto;
    }
  }
}
