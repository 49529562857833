.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.h-10 {
  height: 2.5rem;
}

@media screen and (max-width: $screen-desktop) {

  .clear-header-mobile {
    top: $height-header;
  }
}

.visually-hidden,
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.accent-font {
  font-family: accentFont;
}

.arial-font {
  font-family: arialFont;
}

#main,
#main-scroller {
  position: relative;
  z-index: 1;
}

.feed-grid picture {
  display: flex;
  flex-direction: column;
}

.feed-grid .aem-Grid {
  flex-wrap: wrap;
}

.border-gray-1 {
  border: 1px solid $color-gray;
}


.tracking-tighter {
  letter-spacing: -0.05em;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-normal {
  letter-spacing: 0em;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.tracking-widest {
  letter-spacing: 0.1em;
}